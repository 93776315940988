<route>
{
  "meta": {
    "requiresAuth": true,
    "breadcrumb": [
          { "title": "Home", "url": "/organisation/dashboard" },
          { "title": "Members", "active": true }
      ],
    "title": "Members",
    "resource": "tenant",
    "action": "manage"
  }
}
</route>
<template>
  <div class="organisation dark:bg-gray-500">
    <TenantMembersComponent />
  </div>
</template>

<script>
// @ is an alias to /src
import TenantMembersComponent from '@/modules/organisation/components/OrganisationMemberList.vue';

export default {
  name: 'payment',
  components: {
    TenantMembersComponent,
  },
  layout: 'AppMain',
};
</script>
